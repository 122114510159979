<template>
  <div id="DashboardAuthLayout" class="h-screen font-GTWalsheimPro" :style="{ backgroundImage: `url(${require('@/assets/images/auth-bg.png')})` }">
    <div class="fixed inset-0 bg-black bg-opacity-50 -z--1"></div>
    <div class="z-10 fixed w-full h-full flex items-center justify-center">
      <div class="px-4 w-full sm:max-w-md md:max-w-xl flex flex-col justify-center items-center">
        <div><slot name="header" /></div>
        <slot />
        <div><slot name="footer" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
