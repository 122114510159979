<template>
  <DashboardAuthLayout>
    <template #header>
      <img src="@/assets/images/careercolor.svg" alt="Logo" />
    </template>

    <div class="my-9 p-4 md:p-9 bg-white rounded w-full">
      <div class="flex md:flex-row flex-col-reverse justify-between items-center">
        <div class="flex flex-col">
          <h1 class="font-cooper font-medium text-3xl text-[#090E11]">Verify email address</h1>
          <span class="flex flex-col font-GTWalsheimPro font-normal text-sm text-[#090E11]/80">
            <span>We’ve just sent a 6 digit code to your mail.</span>
            <span>Please check your email. </span>
          </span>
          <p class="text text-brand-black/75">
            <resend-otp v-if="canResendOtp" @resend="triggerRequestOtp" />
            <vue-countdown v-if="!canResendOtp" :time="secondsToResend" v-slot="{ minutes, seconds }"
              @end="canResendOtp = true">
              Resend in {{ minutes }}:{{ seconds }}
            </vue-countdown>
          </p>
          <div class="mt-2">
            <vue-countdown :time="secondsToExpire" v-slot="{ minutes, seconds }" @end="otpExpired">
              OTP code expires in - {{ minutes }}m : {{ seconds }}s
            </vue-countdown>
          </div>
          <div class="mt-2">
            <span>Kindly check your spam folder too, and mark as not spam if there.</span>
          </div>
        </div>
        <div class="order-1">
          <circle-progress :percent="66.6" :size="64" :border-width="6" :border-bg-width="6" fill-color="#B34C99"
            empty-color="#B34C991F">
            <span class="flex flex-row items-center justify-between">
              <span class="font-bold text-3xl text-[#B34C99]">2</span>
              <span class="font-normal text-base text-black/40">/3</span>
            </span>
          </circle-progress>
        </div>
      </div>

      <div>
        <div class="pt-4 space-x-2 md:space-x-4">
          <div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
            <v-otp-input ref="otpInput" input-classes="otp-input" separator="-" :num-inputs="6" :should-auto-focus="true"
              :is-input-num="true" :conditionalClass="['one', 'two', 'three', 'four', 'five', 'six']"
              :placeholder="['*', '*', '*', '*', '*', '*']" @on-change="handleOnChange" @on-complete="handleOnComplete" />
          </div>
        </div>
        <div class="pt-4 space-x-2 md:space-x-4">
          <div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
            <VueRecaptcha :sitekey="siteKey" :load-recaptcha-script="true" @verify="handleSuccess" @error="handleError">
            </VueRecaptcha>
          </div>
        </div>
        <!-- 
        <div class="pt-4">
          <button class="w-full bg-brand-original text-white p-4 rounded-lg" @click="submit">Next</button>
        </div> -->
      </div>
    </div>
  </DashboardAuthLayout>
</template>

<script setup>
import DashboardAuthLayout from '@/layouts/DashboardAuthLayout.vue';
import CircleProgress from '@/components/CircleProgress.vue';
import ResendOtp from '@/components/ResendOtp.vue';
import { errorMessage, alert } from '@/utils/helper';
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { VueRecaptcha } from 'vue-recaptcha';

const otpInput = ref('');
const store = useStore();
const router = useRouter();
const email = ref('');
const captcha = ref('');
const siteKey = computed(() => process.env.VUE_APP_RECAPTCHA_KEY);
const otp = ref('');
const canResendOtp = ref(false);
const secondsToExpire = ref(580990);
const secondsToResend = ref(Math.round(1.2 * 100000));

const handleOnChange = (value) => {
  otp.value = value;
};

const handleOnComplete = (value) => {
  otp.value = value;
  submit(captcha.value);
};

const submit = async (otherValue) => {
  try {
    if (otherValue.length >= 6) {
      const payload = {
        email: await store.getters['global/getFormPart']('email'),
        otp: `${otp.value}`,
        captcha: captcha.value
      };
      const { data } = await store.dispatch('auth/forgetPasswordVerifyOtp', payload);
      if (process.env.NODE_ENV === 'development') {
        console.log(data);
      }
      if (data?.token) {
        await store.commit('auth/setToken', data?.token);
        router.push('/reset-password-form');
      }
    }
  } catch (error) {
    captcha.value = '';
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
};

const handleError = () => { };

const handleSuccess = (response) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(response);
  }
  captcha.value = response;
  submit(otp.value);
};

async function otpExpired() {
  secondsToExpire.value = 0;
  alert('Your otp code has expired, use the resend link to get a new one', 'info', 5000);
  //await requestOtp();
  //secondsToExpire.value = 580990;
}

const triggerRequestOtp = () => {
  canResendOtp.value = false;
  secondsToResend.value = Math.round(secondsToResend.value * 1.7);
  requestOtp();
  secondsToExpire.value = 580990;
};

const requestOtp = async () => {
  try {
    const data = {
      email: await store.getters['global/getFormPart']('email')
    };
    await store.dispatch('auth/forgetPasswordRequestOtp', data);
    alert('A new OTP has been sent to your mail', 'success');

  } catch (error) {
    alert(errorMessage(error) || 'Something went wrong (Please check internent connection)', 'error', 4000);
  }
};

</script>

<style>
.otp-input {
  width: 60px !important;
  height: 60px !important;
  padding: 5px !important;
  margin: 30px 10px 30px 10px !important;
  font-size: 20px !important;
  border-radius: 5px !important;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  text-align: center !important;
}

.otp-input:focus {
  background: #fef6f8;
  border: 1px solid #f0386a;
  border-radius: 5px;
}

@media screen and (max-width: 900px) {
  .otp-input {
    width: 40px !important;
    height: 40px !important;
    flex-wrap: wrap;
  }

  .otp-input:focus {
    background: #fef6f8;
    border: 1px solid #f0386a;
    border-radius: 5px;
  }
}
</style>
